<!--
 * @Description: 填写发票信息 fillInvoice
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:29:48
 * @LastEditors: houjinduo
-->
<template>
  <div class='body'>
    <van-nav-bar title="填写发票信息"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="up style">
      <van-row style="padding-top:25px;padding-bottom:10px">
        <span style="margin-left:15px;font-weight: bold;">发票详情</span>
      </van-row>
      <van-field name="radio"
                 label="抬头类型">
        <template #input>
          <van-radio-group v-model="comPanyRadio"
                           direction="horizontal"
                           @change="handleChangeRadio">
            <van-radio icon-size="13"
                       name="1">企业单位</van-radio>
            <van-radio icon-size="13"
                       name="0">个人/非企业单位</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-row v-if="showCompany===1">
        <van-field v-model="invoiceTitle"
                   label="发票抬头"
                   placeholder="填写发票抬头" />
        <van-field v-model="invoiceValue"
                   label="发票税号"
                   placeholder="填写纳税人识别号" />
        <van-cell title="发票内容"
                  value="停车服务费" />
        <van-cell title="发票金额"
                  :value="invoiceMoney" />
        <van-cell title="更多信息"
                  value="非必填"
                  @click="getMoreInfo"
                  is-link />
      </van-row>
    </van-row>

    <van-row v-if="showCompany===0">
      <van-field v-model="selfName"
                 label="名称"
                 placeholder="请填写名称" />
      <van-cell title="更多信息"
                value="非必填"
                @click="getMoreInfo"
                is-link />
    </van-row>

    <van-row class="content style"
             v-show="moreInfoShow">
      <van-row style="padding-top:25px;padding-bottom:10px">
        <span style="margin-left:15px;font-weight: bold;">更多信息</span>
      </van-row>
      <van-field v-model="address"
                 label="注册地址"
                 placeholder="填写注册地址"
                 :maxlength="100" />
      <van-field v-model="phone"
                 label="注册电话"
                 placeholder="填写注册电话"
                 :maxlength="100" />
      <van-field v-model="bank"
                 label="开户银行"
                 placeholder="填写开户银行"
                 :maxlength="100" />
      <van-field v-model="accountNumber"
                 label="开户账号"
                 placeholder="填写开户账号"
                 :maxlength="100" />
      <van-field v-model="remark"
                 label="备注"
                 type="textarea"
                 placeholder="改内容会打印在发票上"
                 :maxlength="230" />
    </van-row>

    <van-row class="middle style">
      <van-row style="padding-top:25px;padding-bottom:10px">
        <span style="margin-left:15px;font-weight: bold;">接收方式</span>
      </van-row>
      <van-field v-model="userEmail"
                 label="电子邮箱"
                 placeholder="填写邮箱账号"
                 :formatter="formatter"
                 :error-message="errorText" />
      <!-- <van-field v-model="telephone"
                 label="手机号"
                 placeholder="填写手机号"
                 :formatter="formatterTelephone"
                 :error-message="errorTelephoneText" /> -->
    </van-row>
    <van-row class="down style">
      <van-button class="button"
                  @click="handleClickOnSubmit">提 交</van-button>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      moreInfoShow: false, // 点击更多信息出现输入框
      errorText: '',
      // errorTelephoneText: '',
      // telephone: '', // 手机号

      comPanyRadio: '1', // 抬头选择 1为公司 0为个人
      showCompany: 1, // 展示企业或个人 1为企业 0为个人
      invoiceMoney: 0, // 开票金额
      selfName: '', // 个人开票姓名
      userEmail: '', // 电子邮箱
      invoiceTitle: '', // 发票抬头
      invoiceValue: '', // 发票税号
      paymentSequenceList: [], // 开票流水号数组
      userInfo: {},
      address: '', // 注册地址
      phone: '', // 注册电话
      bank: '', // 开户银行
      accountNumber: '', // 开户账号
      remark: '', // 备注
      account: '' // 银行and开户账号
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // 开票金额
    this.invoiceMoney = this.$route.query.invoiceMoney + '元'
    // 选择开票流水号
    this.paymentSequenceList = this.$route.query.paymentSequenceList
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.userInfo = JSON.parse(this.$route.query.userInfo)
    // this.telephone = this.userInfo.phoneNumber
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    formatter (val) {
      const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
      if (!val) return ''
      if (!reg.test(val)) {
        this.errorText = '请输⼊正确的邮箱'
      } else {
        this.errorText = ''
      }
      return val
    },
    // formatterTelephone (val) {
    //   const reg = /^[1][3-9][0-9]{9}$/
    //   if (!val) return ''
    //   if (!reg.test(val)) {
    //     this.errorTelephoneText = '请输⼊正确的手机号'
    //   } else {
    //     this.errorTelephoneText = ''
    //   }
    //   return val
    // },
    // 获取更多信息
    getMoreInfo () {
      this.moreInfoShow = true
    },
    // 点击提交
    handleClickOnSubmit () {
      if (this.comPanyRadio === '1') {
        this.queryCompany()
      } else {
        this.querySelf()
      }
    },
    // 企业
    queryCompany () {
      // else if (!this.telephone) {
      //   this.$toast('请输入手机号!')
      // }
      if (this.invoiceValue.length !== 15 &&
        this.invoiceValue.length !== 17 &&
        this.invoiceValue.length !== 18 &&
        this.invoiceValue.length !== 20) {
        this.$toast('请输入正确的企业税号!')
      } else if (!this.userEmail) {
        this.$toast('请输入电子邮箱!')
      } else {
        const info = {
          paymentSequences: this.paymentSequenceList,
          title: Number(this.comPanyRadio),
          taxnum: this.invoiceValue,
          buyerName: this.invoiceTitle,
          email: this.userEmail,
          telephone: this.phone,
          address: this.address,
          account: this.bank + '    ' + this.accountNumber,
          remark: this.remark
        }
        console.log(info, '$#!$@1')
        this.$invoiceApi.invoice(info).then(() => {
          this.$toast.success('开票成功')
          this.$router.go(-2)
        })
      }
    },
    // 个人
    querySelf () {
      // else if (!this.telephone) {
      //   this.$toast('请输入手机号!')
      // }
      if (!this.userEmail) {
        this.$toast('请输入电子邮箱!')
      } else {
        const info = {
          paymentSequences: this.paymentSequenceList,
          title: Number(this.comPanyRadio),
          buyerName: this.selfName,
          email: this.userEmail,
          telephone: this.phone,
          address: this.address,
          account: this.bank + '    ' + this.accountNumber,
          remark: this.remark
        }
        this.$invoiceApi.invoice(info).then(() => {
          this.$toast.success('开票成功')
          this.$router.go(-2)
        })
      }
    },
    // 抬头类型切换
    handleChangeRadio () {
      this.moreInfoShow = false
      if (this.comPanyRadio === '1') {
        this.showCompany = 1
      } else {
        this.showCompany = 0
      }
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .up {
    // height: 421px;
    margin-top: 12px;

    /deep/.van-radio__label {
      color: #909090 !important;
    }
    .van-cell {
      padding: 15px 16px;
    }
  }
  .content {
    margin-top: 12px;
  }
  .middle {
    margin-top: 12px;
    height: 114px;
    margin-bottom: 60px;
  }
  .down {
    height: 50px;
    position: absolute;
    bottom: 0;
    .button {
      width: 93%;
      height: 40px;
      margin: 5px 3.5%;
      border-radius: 5px;
      background-color: #19a9fc;
      color: white;
    }
  }
}
.style {
  width: 100%;
  background-color: #ffffff;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>
